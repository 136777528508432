<template>
  <div class="shop">
    <!-- 当前店铺 -->
    <div class="current-shop">
      当前店铺：
      <el-select
        v-model="queryInfo.merchantsName"
        placeholder="请选择店铺"
        clearable=""
        filterable
        @clear="getOrderList"
        @change="getOrderList"
      >
        <el-option
          v-for="item in shopList"
          :key="item.id"
          :label="item.merchantsInfo.shopName"
          :value="item.merchantsInfo.shopName"
        >
        </el-option>
      </el-select>
    </div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区 -->
      <div>
        <!-- 查询订单编号 -->
        <div class="orderNo">
          <span>订单编号：</span>
          <el-input
            placeholder="请输入查询的订单编号"
            v-model="queryInfo.orderNo"
            clearable
            @clear="getOrderList"
            @change="searchOrderList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchOrderList"
            ></el-button>
          </el-input>
        </div>
        <!-- 交易时间 -->
        <div class="deal-time">
          <span>交易时间：</span>
          <el-date-picker
            v-model="value1"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            @change="handleChangeTime"
            unlink-panels
          >
          </el-date-picker>
        </div>
        <!-- 查询订单来源 -->
        <div class="order-sourceChannel">
          <span>交易类型：</span>
          <el-select
            v-model="queryInfo.sourceChannel"
            placeholder="全部"
            clearable
            @clear="getOrderList"
            @change="searchOrderList"
          >
            <el-option
              v-for="item in sourceChannelOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!--查询订单状态 -->
        <div class="order-status">
          <div>
            <span>手机号：</span>
            <div class="input">
              <!-- 输入框，尾部带按钮 -->

              <el-input
                placeholder="请输入账号/姓名"
                v-model="queryInfo.mobile"
                clearable
                @clear="getOrderList"
                @change="searchOrderList"
              >
                <el-button
                  slot="append"
                  @click="searchOrderList"
                >搜索</el-button>
              </el-input>
            </div>

          </div>
        </div>
      </div>
      <el-table
        :data="orderList"
        style="width: 100%;"
        border
        stripe
      >
        <el-table-column
          prop="id"
          label="id"
          width="100"
        > </el-table-column>
        <el-table-column
          prop="stallName"
          label="摊位号"
        >
        </el-table-column>
        <el-table-column
          prop="merchantsName"
          label="店铺名称"
        >
        </el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单编号"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="交易时间"
        > </el-table-column>
        <el-table-column
          prop="sourceChannel"
          label="交易类型"
        >
          <template slot-scope="scope">
            <el-tag
              type="primary"
              v-if="scope.row.sourceChannel === 0"
            >线下</el-tag>
            <el-tag
              type="success"
              v-else
            >小程序</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="newOrderAmount"
          label="订单金额（元）"
        >
        </el-table-column>
        <el-table-column
          prop="newDiscountAmount"
          label="优惠金额（元）"
        >
        </el-table-column>
        <el-table-column
          prop="newPayAmount"
          label="实收金额（元）"
        >
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="会员手机号"
        > </el-table-column>

        <el-table-column
          prop="orderStatus"
          label="订单状态"
        >
          <template slot-scope="scope">
            <el-tag
              type="danger"
              v-if="scope.row.orderStatus == 0"
            >待付款</el-tag>
            <el-tag
              type="primary"
              v-else-if="scope.row.orderStatus == 1"
            >待发货</el-tag>
            <el-tag
              type="primary"
              v-else-if="scope.row.orderStatus == 2"
            >已发货</el-tag>
            <el-tag
              type="primary"
              v-else-if="scope.row.orderStatus == 3"
            >配送中</el-tag>
            <el-tag
              type="success"
              v-else-if="scope.row.orderStatus == 4"
            >已完成</el-tag>
            <el-tag
              type="info"
              v-else-if="scope.row.orderStatus == 5"
            >已关闭</el-tag>
            <el-tag
              type="danger"
              v-else-if="scope.row.orderStatus == 6"
            >退款中</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="getOrderDetail(scope.row.id)"
            >查看</el-button>
            <!-- <el-button type="danger"
                       size="mini"
                       @click="deleteMarket(scope.row.id)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      title="订单详情"
      :visible.sync="orderDialogVisible"
      width="900px"
      @close="orderDialogClosed"
    >
      <div class="order-list">
        <!-- 第一行 -->
        <div class="first">
          <div class="goods-list">
            <div class="goods-name">店铺名：</div>
            <div class="goods-info">{{ orderDetail.merchantsName }}</div>
          </div>
          <div class="goods-list">
            <div class="goods-name">经营人：</div>
            <div class="goods-info">{{ orderDetail.userName }}</div>
          </div>
          <div class="goods-list">
            <div class="goods-name">店铺地址：</div>
            <div class="goods-info">{{ orderDetail.merchantsAddress }}</div>
          </div>
        </div>
        <!-- 第二行 -->
        <div class="second">
          <div class="goods-list">
            <div class="goods-name">订单金额：</div>
            <div class="goods-info">￥{{ orderDetail.orderAmount / 100 }}</div>
          </div>
          <div class="goods-list">
            <div class="goods-name">优惠金额：</div>
            <div class="goods-info">
              ￥{{ orderDetail.discountAmount / 100 }}
            </div>
          </div>
          <div
            class="goods-list"
            v-if="orderDetail.sendCost > 0"
          >
            <div>
              <div class="goods-name">配送费：</div>
              <div class="goods-info">￥{{ orderDetail.sendCost / 100 }}</div>
            </div>
          </div>
          <div
            class="goods-list"
            v-else-if="orderDetail.sendCost === 0"
          >
            <div>
              <div class="goods-name">配送费：</div>
              <div class="goods-info">
                免配送费
              </div>
            </div>
          </div>
          <div
            class="goods-list"
            v-else
          >
            <div>
              <div class="goods-name">配送费：</div>
              <div class="goods-info">
                线下订单无配送费
              </div>
            </div>
          </div>
        </div>
        <!-- 第三行 -->
        <div class="third">
          <div class="goods-list">
            <div class="goods-name">实付金额：</div>
            <div class="goods-info">￥{{ orderDetail.payAmount / 100 }}</div>
          </div>
          <div class="goods-list">
            <div class="goods-name">订单时间：</div>
            <div class="goods-info">{{ orderDetail.createTime }}</div>
          </div>

          <div class="goods-list">
            <div class="goods-name">订单编号：</div>
            <div class="goods-info">{{ orderDetail.orderNo }}</div>
          </div>
        </div>
        <!-- 第四行 -->
        <div class="four">
          <div class="goods-list">
            <div class="goods-name">订单状态：</div>
            <div class="goods-info">
              <span v-if="orderDetail.orderStatus == 0">待付款</span>
              <span
                type="primary"
                v-else-if="orderDetail.orderStatus == 1"
              >待发货</span>
              <span
                type="primary"
                v-else-if="orderDetail.orderStatus == 2"
              >已发货</span>
              <span
                type="primary"
                v-else-if="orderDetail.orderStatus == 3"
              >配送中</span>
              <span
                type="success"
                v-else-if="orderDetail.orderStatus == 4"
              >已完成</span>
              <span
                type="info"
                v-else-if="orderDetail.orderStatus == 5"
              >已关闭</span>
              <span
                type="danger"
                v-else-if="orderDetail.orderStatus == 6"
              >退款中</span>
            </div>
          </div>
          <div class="goods-list">
            <div class="goods-name">交易类型：</div>
            <div class="goods-info">
              <span v-if="orderDetail.sourceChannel == 0">线下</span>
              <span v-else>小程序</span>
            </div>
          </div>

          <div class="goods-list">
            <div class="goods-name">支付方式：</div>
            <div class="goods-info">
              <span
                type="primary"
                v-if="orderDetail.payType == 0"
              >其他支付</span>
              <span
                type="primary"
                v-else-if="orderDetail.payType == 1"
              >支付宝</span>
              <span
                type="primary"
                v-else-if="orderDetail.payType == 2"
              >微信</span>
              <span
                type="primary"
                v-else-if="orderDetail.payType == 3"
              >现金</span>
            </div>
          </div>
        </div>
        <!-- 第五行 -->
        <div class="five">
          <div
            class="goods-list"
            v-if="orderDetail.userMobile"
          >
            <div class="goods-name">会员手机号：</div>
            <div class="goods-info">{{ orderDetail.userMobile }}</div>
          </div>
          <div
            class="goods-list"
            v-if="orderDetail.remark"
            style="margin-left:-13px"
          >
            <div class="goods-name">备注：</div>
            <div class="goods-info">{{ orderDetail.remark }}</div>
          </div>
        </div>
      </div>
      <el-table :data="orderDetail.productList">
        <el-table-column
          label="商品id"
          prop="productId"
        ></el-table-column>
        <el-table-column
          label="商品图片"
          prop="productIcon"
        >
          <template slot-scope="scope">
            <img
              style="width:80px;height:80px;"
              :src="scope.row.productIcon"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="商品名称"
          prop="productName"
        ></el-table-column>
        <el-table-column label="单价">
          <template slot-scope="scope">
            <div v-if="scope.row.productFeeType === 0">
              {{ scope.row.productAmount / 100 }}元/kg
            </div>
            <div v-else>{{ scope.row.productAmount / 100 }}元/份</div>
          </template>
        </el-table-column>
        <el-table-column
          label="优惠（元）"
          prop="newDiscountAmountDetail"
        ></el-table-column>
        <el-table-column
          label="数量"
          prop="productNumber"
        ></el-table-column>

        <el-table-column
          label="小计（元）"
          prop="newSubTotalAmountDetail"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data () {
    return {
      queryInfo: {
        limit: 15,
        pageNo: 1,
        orderNo: '',
        mobile: '',
        merchantsName: this.$route.query.name,
        sourceChannel: '',
        minCreateTime: '',
        maxCreateTime: ''
      },
      queryInfoShop: {
        limit: 1000,
        pageNo: 1,
        shopName: '',
        marketId: ''
      },
      total: 0,
      orderList: [],
      title: '',
      orderDialogVisible: false,
      id: '', // 订单商品的订单编号
      // 订单时间筛选的绑定值
      value1: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
      // 订单详情
      orderDetail: '',
      // 订单来源查询
      sourceChannelOpts: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '0',
          label: '线下'
        },
        {
          value: '1',
          label: '小程序'
        }
      ],
      // 订单状态查询
      orderStatusOpts: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '0',
          label: '待付款'
        },
        {
          value: '1',
          label: '待发货'
        },
        {
          value: '2',
          label: '已发货'
        },
        {
          value: '3',
          label: '配送中'
        },
        {
          value: '4',
          label: '已完成'
        },
        {
          value: '5',
          label: '已关闭'
        },
        {
          value: '6',
          label: '退款中'
        }
      ],
      shopList: []
    }
  },
  created () {
    this.queryInfo.mobile = this.$route.query.mobile
    this.getOrderList()
    this.getShopProductList()
    // this.getClickOrderList()
  },
  methods: {
    searchOrderList () {
      this.queryInfo.pageNo = 1
      this.getOrderList()
    },
    handleChangeTime () {
      this.queryInfo.pageNo = 1
      this.getOrderList()
    },
    async getOrderList () {
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const [min, max] = this.value1 || [undefined, undefined] || [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]

      this.queryInfo.minCreateTime = min
      this.queryInfo.maxCreateTime = max
      const { data: res } = await this.$http.get('/orderMain/orderMainList', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.orderList = res.data.data.map((item) => ({
        ...item,
        newSourceChannel: item.sourceChannel === 0 ? '线下' : '小程序',
        newOrderAmount: item.orderAmount / 100,
        newDiscountAmount: item.discountAmount / 100,
        newPayAmount: item.payAmount / 100
      }))
      this.total = res.data.total
    },
    async getOrderDetail (id) {
      this.id = id
      const { data: res } = await this.$http.get(`/orderMain/${this.id}`)
      // console.log(res, 111)
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.orderDetail = res.data
      this.orderDetail.productList = this.orderDetail.productList.map(
        (item) => ({
          ...item,
          newProductAmountDetail: item.productAmount / 100,
          newDiscountAmountDetail: item.discountAmount / 100,
          newSubTotalAmountDetail: item.subTotalAmount / 100
        })
      )
      this.orderDialogVisible = true
    },
    orderDialogClosed () { },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getOrderList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getOrderList()
    },
    async getShopProductList () {
      // 获取marketId
      this.queryInfoShop.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get('merchants/list', {
        params: this.queryInfoShop
      })
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.shopList = res.data.data
    }
  }
}
</script>

<style lang="less" scoped>
.orderNo {
  width: 200px;
  .el-input {
    width: 243px;
    margin-left: 70px;
    top: -30px;
  }
}
// 交易时间
.deal-time {
  width: 260px;
  margin-left: 325px;
  margin-top: -60px;
}
.el-date-editor--daterange.el-input__inner {
  width: 230px;
  margin-left: 80px;
  top: -32px;
}
.order-sourceChannel {
  margin-left: 640px;
  margin-top: -71px;
  .el-select {
    width: 90px;
  }
}
.order-status {
  // width: 300px;
  margin-left: 820px;
  margin-top: -30px;
  .input {
    width: 221px;
    margin-left: 60px;
    margin-top: -30px;
  }
  // .el-select {
  //   width: 100px;
  // }
}
.order-list {
  // display: flex;
  // justify-content: flex-start;
  // flex-wrap: wrap;
  .goods-list {
    // margin-left: 30px;
    margin-bottom: 25px;
    width: 300px;
    .goods-name {
      color: #c3c3c3;
    }
    .goods-info {
      margin-left: 80px;
      margin-top: -20px;
    }
  }
}

.first,
.second,
.third,
.four,
.five {
  display: flex;
  // justify-content: space-between;
  // justify-content: flex-start;
}
.shop {
  position: relative;
}
.current-shop {
  position: absolute;
  top: -60px;
  left: 353px;
}
</style>
